<template>
  <a-card>
    <a-page-header
      :title="this.$route.query.goods_name"
      :sub-title="this.$route.query.brief"
      @back="() => this.$router.go(-1)" >
    </a-page-header>
    <s-table
      ref="table"
      size="small"
      :loading="loading"
      :columns="columns"
      :data="loadData"
      rowKey="id"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text">
        {{ text / 100 | numberFormat }}
      </div>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/a-applet/shop_goods/goods_price/CreateForm'

import {
  getGoodsCountRecordList
} from '@/api/applet_goods'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: true,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '原数量',
          dataIndex: 'pre_count',
          ellipsis: true
        },
        {
          title: '数量',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '时间',
          dataIndex: 'create_time',
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return getGoodsCountRecordList(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    this.queryParam.goods_id = this.$route.query.goods_id
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
